import { useState, useEffect } from "react"

export const useIsHomePageV2 = () => {
  const [isHomePageV2, setIsHomePageV2] = useState(true)

  // const checkABTests = (retries = 0) => {
  //   if (typeof window !== "undefined" && window?.abtesting) {
  //     if (window?.abtesting?.tests?.hubble) {
  //       setIsHomePageV2(true)
  //     }
  //   } else if (retries < 50) {
  //     setTimeout(() => checkABTests(retries+1), 100)
  //   }
  // }

  // useEffect(() => {checkABTests()}, [])

  return isHomePageV2
}

export default useIsHomePageV2
